import React from "react";
import {
  PiMouseLeftClickFill,
  PiMouseRightClickFill,
  PiMouseScrollFill,
} from "react-icons/pi";
import { isScreenTouchEnabled } from "./utils/isScreenTouchEnabled";
import { TbHandTwoFingers } from "react-icons/tb";
import { MdOutlinePinch, MdOutlineSwipe } from "react-icons/md";

export default function Guide() {
  const isTouchScreen = isScreenTouchEnabled();

  const guide = isTouchScreen ? (
    <>
      <p>
        <MdOutlineSwipe />: Rotate
      </p>
      <p>
        <TbHandTwoFingers />: Pan
      </p>
      <p>
        <MdOutlinePinch />: Zoom
      </p>
    </>
  ) : (
    <>
      <p>
        <PiMouseLeftClickFill />: Rotate
      </p>
      <p>
        <PiMouseRightClickFill />: Pan
      </p>
      <p>
        <PiMouseScrollFill />: Zoom
      </p>
    </>
  );

  return <div className="guide">{guide}</div>;
}
