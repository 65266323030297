import { Canvas } from "@react-three/fiber";
import Experience from "../Experience";

const Platform = () => {
  return (
    <Canvas
      shadows
      className="portfolio-canvas"
      camera={{
        fov: 50,
        near: 0.1,
        far: 1000,
        position: [3, 1.5, 5],
      }}
    >
      <Experience />
    </Canvas>
  );
};

export default Platform;
