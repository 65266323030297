import { useState } from "react";
import Credit from "./Credit";
import Guide from "./Guide";
import Platform from "./Platform/Platform";
import Welcome from "./Welcome/Welcome";

const App = () => {
  const [isStarted, setIsStarted] = useState(false);

  return (
    <>
      <Welcome isStarted={isStarted} onStarted={() => setIsStarted(true)} />
      {isStarted && (
        <>
          <Guide />
          <Platform />
        </>
      )}
      <Credit />
    </>
  );
};

export default App;
