import { useState } from "react";
import "./Welcome.scss";
import "./GlowingButton.scss";
import { isMobile } from "react-device-detect";

type WelcomeProps = {
  isStarted: boolean;
  onStarted: () => void;
};

const Welcome = ({ isStarted, onStarted }: WelcomeProps) => {
  const isShowingLocalStorage =
    localStorage.getItem("isShowingWelcomeScreen") ?? "true";
  const [isShowingWelcomeScreen, setIsShowingWelcomeScreen] = useState(
    isShowingLocalStorage === "true"
  );

  const acceptWelcome = () => {
    setIsShowingWelcomeScreen(false);
    localStorage.setItem("isShowingWelcomeScreen", "false");
  };

  return (
    <>
      {!isStarted && (
        <>
          {isShowingWelcomeScreen && (
            <div className="welcome">
              <>
                <h1>A quick note before we begin...</h1>

                {isMobile && (
                  <>
                    <p>
                      This experience is not optimized for mobile, but you can
                      still explore the platform.
                    </p>
                    <p>I'm working on a mobile version, so stay tuned!</p>
                  </>
                )}

                {!isMobile && (
                  <>
                    <p>
                      This experience is intended for high-performance devices.
                    </p>

                    <p>
                      If you are on low-performance devices, some features may
                      not work as expected. Please feel free to post your issue
                      on{" "}
                      <a
                        href="https://github.com/klovack/klovack.github.io/issues"
                        target="__blank"
                      >
                        Github
                      </a>
                    </p>

                    <p>
                      Please use the latest version of Google Chrome for the
                      best experience.
                    </p>
                  </>
                )}

                <div className="welcome__cta">
                  {isMobile && (
                    <button className="welcome__cta__mobile" disabled>
                      Switch to Mobile Version
                    </button>
                  )}
                  <button
                    className="welcome__cta__start"
                    onClick={acceptWelcome}
                  >
                    Show Me!
                  </button>
                </div>
              </>
            </div>
          )}
          {!isShowingWelcomeScreen && (
            <div className="welcome-without-start">
              <button
                className="glowing-btn"
                onClick={() => {
                  onStarted();
                }}
              >
                <span className="glowing-txt">
                  S<span className="faulty-letter">T</span>AR
                  <span className="faulty-letter">T</span>
                </span>
              </button>
            </div>
          )}
          <div className="welcome-mask"></div>
        </>
      )}
    </>
  );
};

export default Welcome;
