import { OrbitControls, OrbitControlsProps } from "@react-three/drei";
import { clamp } from "three/src/math/MathUtils.js";
import { useRef } from "react";
import { OrbitControls as OrbitControlsImpl } from "three-stdlib";

export type FocusPoint = {
  target: [x: number, y: number, z: number];
  azimuthAngle: number;
  polarAngle: number;
  minDistance: number;

  /**
   * if empty or undefined, it will be set to `minDistance + 0.2`
   */
  maxDistance?: number;
  /**
   * Rotation, pan, and zoom speed
   * @default 2
   */
  speed?: number;
  /**
   * whether to enable controls
   * @default false
   */
  enabled?: boolean;

  panLimit:
    | [
        minX: number,
        maxX: number,
        minY: number,
        maxY: number,
        minZ: number,
        maxZ: number
      ]
    | number;
};

const defaultFocusPoint: FocusPoint & {
  maxDistance: number;
  speed: number;
} = {
  target: [-0.8, 0.2, -0.5],
  azimuthAngle: 0.6,
  polarAngle: 1.24,
  minDistance: 2,
  maxDistance: 7,
  speed: 0.5,
  enabled: true,
  panLimit: 3,
};

type PortfolioControlProps = OrbitControlsProps;

export default function PortfolioControl({ ...props }: PortfolioControlProps) {
  const controlsRef = useRef<OrbitControlsImpl>(null);

  const limitPanning = () => {
    const target = controlsRef.current?.target;

    if (!target) {
      return;
    }

    let targetX: number = 0;
    let targetY: number = 0;
    let targetZ: number = 0;
    if (Array.isArray(defaultFocusPoint.panLimit)) {
      targetX = clamp(
        target.x,
        defaultFocusPoint.panLimit[0],
        defaultFocusPoint.panLimit[1]
      );
      targetY = clamp(
        target.x,
        defaultFocusPoint.panLimit[2],
        defaultFocusPoint.panLimit[3]
      );
      targetY = clamp(
        target.x,
        defaultFocusPoint.panLimit[3],
        defaultFocusPoint.panLimit[5]
      );
    } else {
      targetX = clamp(
        target.x,
        -defaultFocusPoint.panLimit,
        defaultFocusPoint.panLimit
      );
      targetY = clamp(
        target.y,
        -defaultFocusPoint.panLimit,
        defaultFocusPoint.panLimit
      );
      targetZ = clamp(
        target.z,
        -defaultFocusPoint.panLimit,
        defaultFocusPoint.panLimit
      );
    }

    target.setX(targetX);
    target.setY(targetY);
    target.setZ(targetZ);
  };

  return (
    <>
      <OrbitControls
        ref={controlsRef}
        makeDefault
        minAzimuthAngle={-Math.PI / 4}
        maxAzimuthAngle={Math.PI / 2}
        minPolarAngle={Math.PI / 12}
        maxPolarAngle={Math.PI / 2}
        dampingFactor={0.1}
        enablePan={true}
        enableZoom={true}
        maxDistance={defaultFocusPoint.maxDistance}
        minDistance={defaultFocusPoint.minDistance}
        rotateSpeed={defaultFocusPoint.speed}
        panSpeed={defaultFocusPoint.speed}
        zoomSpeed={defaultFocusPoint.speed}
        // to debug camera position
        // onEnd={() => {
        //   console.log({
        //     target: controlsRef.current?.target,
        //     azimuth: controlsRef.current?.getAzimuthalAngle(),
        //     polar: controlsRef.current?.getPolarAngle(),
        //     zoom: controlsRef.current?.getDistance(),
        //     a: controlsRef.current?.dampingFactor,
        //   });
        //   console.log({
        //     camera: controlsRef.current?.object,
        //     position: controlsRef.current?.object.position,
        //   });
        // }}
        onChange={limitPanning}
        {...props}
      />
    </>
  );
}
