import { animated, config, useSpring } from "@react-spring/three";
import { Text3D } from "@react-three/drei";
import React, { PropsWithChildren, Suspense, useState } from "react";
import pointerEvents from "./utils/pointerEvents";
import { Euler, MeshProps, useLoader, Vector3 } from "@react-three/fiber";
import { NearestFilter, TextureLoader } from "three";
import gradientTexture from "./assets/images/5-tone.jpg";
import { TextGeometryParameters } from "three-stdlib";
import { useLighting } from "./providers/LightingProvider";
import { FONTS } from "./assets";
import { COLORS } from "./styles/colors";
import InteractMarker from "./InteractMarker/InteractMarker";
import TextArrow from "./Arrow/TextArrow";

const lightSwitchSound = new Audio("sfx/light-switch.m4a");

type LightableText3DProps = PropsWithChildren &
  MeshProps &
  Omit<TextGeometryParameters, "font"> & {
    offColor: string;
    onColor: string;
    onChange?: (isOn: boolean) => void;
    markerPosition?: Vector3;
    markerRotation?: Euler;
    markerScale?: number;
    hasMarker?: boolean;
  };

const LightableText3D = ({
  children,
  onColor,
  offColor,
  onChange,
  markerPosition = [0.1, 0.4, 0.1],
  markerRotation = [-Math.PI / 2, 0, 0],
  markerScale = 1,
  hasMarker = false,
  ...props
}: LightableText3DProps) => {
  const gradient5ToneMap = useLoader(TextureLoader, gradientTexture);
  gradient5ToneMap.minFilter = gradient5ToneMap.magFilter = NearestFilter;
  gradient5ToneMap.generateMipmaps = false;
  const [isTextOn, setIsTextOn] = React.useState(false);

  const [spring, api] = useSpring(() => ({
    textColor: offColor,
    config: config.stiff,
  }));

  const switchLight = (isOn: boolean) => {
    setIsTextOn((prev) => !prev);
    lightSwitchSound.play();
    api.start({
      textColor: isOn ? onColor : offColor,
    });
    onChange?.(isOn);
  };

  return (
    <Text3D
      font={FONTS.BEBAS_JSON}
      bevelEnabled
      bevelSize={0.01}
      bevelThickness={0.01}
      letterSpacing={0.1}
      onClick={(e) => {
        e.stopPropagation();
        switchLight(!isTextOn);
      }}
      castShadow
      {...props}
      {...pointerEvents}
    >
      {children}
      <animated.meshToonMaterial
        color={spring.textColor}
        gradientMap={gradient5ToneMap}
      />
      {hasMarker && (
        <InteractMarker
          visible={!isTextOn}
          position={markerPosition}
          rotation={markerRotation}
          scale={markerScale}
        />
      )}
    </Text3D>
  );
};

export default function Name() {
  const { toggleLight, addDevice } = useLighting();
  const [isFirstNameOn, setIsFirstNameOn] = useState(false);
  addDevice("firstName", 1);
  addDevice("lastName", 1);

  return (
    <Suspense>
      <TextArrow
        visible={!isFirstNameOn}
        direction="DOWN"
        position={[-1.9, -0.2, 0.5]}
        rotation={[0, Math.PI / 2.8, 0]}
        mirror
      >
        Switch On!
      </TextArrow>
      <LightableText3D
        offColor={COLORS.DARK_PRIMARY_COLOR}
        onColor={COLORS.PRIMARY_COLOR}
        size={0.4}
        height={0.1}
        position={[-1.9, -1.0, 0.8]}
        rotation-x={-0}
        rotation-y={1.6}
        curveSegments={12}
        onChange={(isOn) => {
          toggleLight("firstName", isOn);
          setIsFirstNameOn(isOn);
        }}
      >
        Rizki
      </LightableText3D>

      <LightableText3D
        offColor={COLORS.DARK_PRIMARY_COLOR}
        onColor={COLORS.PRIMARY_COLOR}
        size={0.32}
        height={0.07}
        position={[-1.45, -1.0, 1.7]}
        rotation-x={-Math.PI / 2}
        rotation-y={0}
        curveSegments={12}
        onChange={(isOn) => {
          toggleLight("lastName", isOn);
        }}
        markerRotation={[0, 0, 0]}
        markerPosition={[0.2, 0.1, 0.09]}
        hasMarker
      >
        Fikriansyah
      </LightableText3D>
    </Suspense>
  );
}
