import { Text } from "@react-three/drei";
import { FONTS } from "../../assets";
import { COLORS } from "../../styles/colors";
import { isMobile } from "react-device-detect";

const Loading3D = () => {
  const scale = isMobile ? 0.2 : 0.4;

  return (
    <Text
      font={FONTS.NDOT_47_OTF}
      letterSpacing={0.5}
      scale={scale}
      rotation={[0, Math.PI / 6, 0]}
      position={[0, 0, 0.5]}
      color={COLORS.PRIMARY_COLOR}
    >
      Loading...
    </Text>
  );
};

export default Loading3D;
