import LaptopScreen from "../LaptopScreen/LaptopScreen";
import Macbook from "../Models/Macbook";
import Name from "../Name";
import PhoneScreen from "../PhoneScreen/PhoneScreen";
import PortfolioControl from "../PortfolioControl";
import StickyNotes from "../StickyNotes";
import { useContext } from "react";
import { FocusDeviceContext } from "../providers/FocusDeviceProvider";
import Speaker from "../Models/Speaker/Speaker";

const Interactables = () => {
  const { isMacbookOn, setIsMacbookOn } = useContext(FocusDeviceContext);

  return (
    <>
      <PortfolioControl />
      <Macbook
        castShadow
        isInteractionEnabled={!isMacbookOn}
        position-y={-1.5}
      />
      <LaptopScreen
        isLaptopOn={isMacbookOn}
        setIsLaptopOn={(isLaptopOn) => {
          setIsMacbookOn(isLaptopOn);
        }}
      />

      <Speaker />

      <PhoneScreen />
      <StickyNotes />
      <Name />
    </>
  );
};

export default Interactables;
