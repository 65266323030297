import { Text, useBounds } from "@react-three/drei";
import React, { useEffect } from "react";
import { animated, easings, useSpring } from "@react-spring/three";
import pointerEvents from "./utils/pointerEvents";
import { FONTS } from "./assets";
import { Group } from "three";
import InteractMarker from "./InteractMarker/InteractMarker";
import { COLORS } from "./styles/colors";

const constants = {
  SOCIAL_MEDIA_NOTE_POSITION: [2.56, -1.03, 0.6],
  SOCIAL_MEDIA_NOTE_REVEALED_POSITION: [2.93, -1.03, 0.7],
  SOCIAL_MEDIA_NOTE_REVEALED_DURATION: 500,
};

export default function StickyNotes() {
  const [isSocialMediaNoteRevealed, setIsSocialMediaNoteRevealed] =
    React.useState(false);
  const [isAboutNoteSelected, setIsAboutNoteSelected] = React.useState(false);
  const mediaNoteRef = React.useRef<Group | null>(null);
  const aboutNoteRef = React.useRef<Group | null>(null);
  const boundsApi = useBounds();

  const [spring, springApi] = useSpring(() => ({
    position: constants.SOCIAL_MEDIA_NOTE_POSITION,

    config: {
      mass: 1,
      tension: 170,
      friction: 26,
      duration: constants.SOCIAL_MEDIA_NOTE_REVEALED_DURATION,
      easing: easings.easeOutSine,
    },
  }));

  useEffect(() => {
    if (isSocialMediaNoteRevealed) {
      springApi.start({
        position: constants.SOCIAL_MEDIA_NOTE_REVEALED_POSITION,
      });

      if (mediaNoteRef.current) {
        boundsApi.refresh(mediaNoteRef.current).clip().fit();
      }
    } else {
      springApi.start({
        position: constants.SOCIAL_MEDIA_NOTE_POSITION,
      });

      boundsApi.refresh().clip().fit();
    }
  }, [boundsApi, isSocialMediaNoteRevealed, springApi]);

  useEffect(() => {
    if (isAboutNoteSelected) {
      if (aboutNoteRef.current) {
        boundsApi.refresh(aboutNoteRef.current).clip().fit();
      }
    } else {
      boundsApi.refresh().clip().fit();
    }
  }, [isAboutNoteSelected, boundsApi]);

  const handleSocialMediaNoteClick = () => {
    setIsSocialMediaNoteRevealed(!isSocialMediaNoteRevealed);
  };

  return (
    <>
      <group
        position={[1.25, -0.98, 0.7]}
        rotation-y={-0.2}
        onClick={(e) => {
          e.stopPropagation();
          setIsAboutNoteSelected(!isAboutNoteSelected);
        }}
        ref={aboutNoteRef}
        {...pointerEvents}
      >
        <InteractMarker
          visible={!isAboutNoteSelected}
          position={[0, 0.011, 0]}
        />
        <mesh scale={[0.7, 0.01, 0.8]}>
          <boxGeometry />
          <meshStandardMaterial color={"#C0B375"} />
        </mesh>
        <Text
          font={FONTS.INDIE_FLOWER_TTF}
          fontSize={0.08}
          position={[0.02, 0.01, 0]}
          rotation={[-Math.PI / 2, 0, 0]}
          color={COLORS.TEXT_PRIMARY_COLOR}
          maxWidth={0.68}
          lineHeight={1.6}
        >
          Explore, discover, and let it inspire you. {"\n"}This is where code
          meets creativity.
        </Text>
      </group>

      <animated.group
        position={spring.position.to((x, y, z) => [x, y, z])}
        rotation-y={0.3}
        onClick={handleSocialMediaNoteClick}
        ref={mediaNoteRef}
        {...pointerEvents}
      >
        <InteractMarker
          visible={!isSocialMediaNoteRevealed}
          position={[0.3, 0.0315, 0.3]}
        />
        <mesh scale={[0.7, 0.005, 0.8]}>
          <boxGeometry />
          <meshStandardMaterial color={"#C0B375"} />
        </mesh>
        <Text
          font={FONTS.INDIE_FLOWER_TTF}
          fontSize={0.08}
          position={[0.02, 0.0305, 0]}
          rotation={[-Math.PI / 2, 0, 0]}
          color={COLORS.TEXT_PRIMARY_COLOR}
          maxWidth={0.6}
        >
          Got a project in mind? I’m all ears and ready to connect.
        </Text>
      </animated.group>
    </>
  );
}
