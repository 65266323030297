import { Bounds, Plane, SpotLight } from "@react-three/drei";
import FocusDeviceProvider from "./providers/FocusDeviceProvider";
import Interactables from "./Interactables/Interactables";
import { Perf } from "r3f-perf";
import { isProduction } from "./utils/isProduction";
import Particles from "./Particles/Particles";
import { isMobile } from "react-device-detect";
import LightingProvider from "./providers/LightingProvider";
import { Suspense } from "react";
import Loading3D from "./Fallback/Loading3D/Loading3D";

export default function Experience() {
  const isDevelopment = !isProduction();

  return (
    <Suspense fallback={<Loading3D />}>
      {isDevelopment && (
        <Perf openByDefault trackGPU={true} position="top-right" />
      )}
      <color args={["#0a0a0a"]} attach="background" />
      <SpotLight
        position={[-4, 5, 0]}
        angle={2}
        distance={15}
        attenuation={12}
        radiusTop={2}
        radiusBottom={3.5}
        color={"#FFF5B6"}
        penumbra={0.5}
        opacity={0.3}
        anglePower={4}
        intensity={0.1}
        castShadow
      />
      {!isMobile && (
        <directionalLight
          color="#fafada"
          position={[-5.6, 1.7, 5]}
          intensity={1}
        />
      )}

      {!isMobile && <Particles />}

      <LightingProvider
        color={"#BECFA5"}
        maxIntensity={10}
        minIntensity={0}
        intensity={0}
      >
        <FocusDeviceProvider>
          <Bounds fit clip observe margin={1} maxDuration={1}>
            <Interactables />
          </Bounds>
        </FocusDeviceProvider>
      </LightingProvider>

      <Plane
        scale={[200, 200, 1]}
        rotation-x={-Math.PI / 2}
        position={[0, -1, 0]}
        receiveShadow
      >
        <shadowMaterial attach="material" opacity={0.5} />
      </Plane>
    </Suspense>
  );
}
