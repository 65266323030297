import { Sparkles } from "@react-three/drei";
import { useState, useEffect } from "react";

const Particles = () => {
  const particleSizes = Float32Array.from(
    { length: 100 },
    () => Math.random() * 2
  );

  const [isShadowReady, setIsShadowReady] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsShadowReady(true);
    }, 250);
  });

  return (
    <>
      {isShadowReady && (
        <Sparkles
          castShadow={false}
          receiveShadow={false}
          size={particleSizes}
          position={[0, 1, 0]}
          scale={5}
          count={100}
          color={"#FFF5B6"}
        />
      )}
    </>
  );
};

export default Particles;
