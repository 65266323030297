import { MeshProps, useLoader } from "@react-three/fiber";
import { TextureLoader } from "three";
import PointTexturePath from "../assets/images/point.png";

export type InteractMarkerProps = MeshProps & {};

const InteractMarker = ({ ...props }: InteractMarkerProps) => {
  const pointTexture = useLoader(TextureLoader, PointTexturePath);

  return (
    <mesh rotation={[-Math.PI / 2, 0, 0]} {...props}>
      <planeGeometry args={[0.2, 0.2]} />
      <meshBasicMaterial map={pointTexture} transparent />
    </mesh>
  );
};

export default InteractMarker;
