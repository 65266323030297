import { Html, useBounds } from "@react-three/drei";
import { Suspense, useContext, useEffect, useState } from "react";
import Desktop from "../Desktop/Desktop";
import pointerEvents from "../utils/pointerEvents";
import { FONTS } from "../assets";
import { COLORS } from "../styles/colors";
import { FocusDeviceContext } from "../providers/FocusDeviceProvider";
import { isMobile } from "react-device-detect";
import TextArrow from "../Arrow/TextArrow";

const powerButtonColors = {
  OFF: "#343434",
  ON: "#fafafa",
};

export default function LaptopScreen(props: {
  isLaptopOn: boolean;
  setIsLaptopOn: (isLaptopOn: boolean) => void;
  onLaptopOn?: () => void;
  onLaptopOff?: () => void;
}) {
  const { isLaptopOn, setIsLaptopOn, onLaptopOff, onLaptopOn } = props;
  const [buttonColor, setButtonColor] = useState(powerButtonColors.OFF);
  const bounds = useBounds();
  const bootSound = new Audio("sfx/boot-sound.m4a");
  bootSound.volume = 0.5;
  const clickSound = new Audio("sfx/keyboard-click.wav");
  const { isMacbookOpen } = useContext(FocusDeviceContext);

  useEffect(() => {
    if (isLaptopOn) {
      onLaptopOn?.();
      bounds.moveTo([-0.102, 1.03, 2.19]).lookAt({
        target: [0.010180646082962388, 0.1523548337264966, -1],
      });
    } else {
      onLaptopOff?.();
      bounds.refresh().clip().fit();
    }
  }, [isLaptopOn, onLaptopOff, onLaptopOn, bounds]);

  const focusDeviceContext = useContext(FocusDeviceContext);

  return (
    <group position-y={-1.5}>
      <mesh
        position={[0, 0.51, -0.4]}
        scale={[2.5, 0.04, 1]}
        {...pointerEvents}
        onClick={(e) => {
          clickSound.play();
          setButtonColor(
            isLaptopOn ? powerButtonColors.OFF : powerButtonColors.ON
          );
          e.stopPropagation();
          if (!isLaptopOn) {
            bootSound.play();
          }

          setIsLaptopOn(!isLaptopOn);
        }}
        name="powerButton"
      >
        <boxGeometry />
        <meshBasicMaterial color={buttonColor} transparent opacity={0.2} />
      </mesh>

      <TextArrow
        visible={isMacbookOpen && !isLaptopOn}
        font={FONTS.INDIE_FLOWER_TTF}
        position={[0.1, 0.88, -0.45]}
        textAlign="center"
        color={COLORS.SECONDARY_COLOR}
        direction="DOWN"
      >
        Turn on the laptop
      </TextArrow>

      {isLaptopOn && (
        <Suspense>
          <Html
            occlude={"blending"}
            zIndexRange={[100, 0]}
            transform
            wrapperClass="htmlPortfolio"
            distanceFactor={0.94}
            position={[0, 1.57, isMobile ? -1.3 : -1.42]}
            rotation-x={-0.256}
            receiveShadow
          >
            <FocusDeviceContext.Provider value={focusDeviceContext}>
              <Desktop />
            </FocusDeviceContext.Provider>
          </Html>
        </Suspense>
      )}
    </group>
  );
}
